import router from './index';
import {RouteRecordRaw} from "vue-router";

// 所有的前端页面路由放到这来，然后通过这拿去注册路由
const routerList = [] as any;

// 将后端菜单数据与前端路由匹配
const dealWithRoute = (use: Array<any>, all: Array<any> = routerList) => {
  if (routerList.length === 0) {
    all = use
  }
  const result: Array<any> = [];
  all.forEach((_all) => {
    use.forEach((_use) => {
      // 这匹配值根据后台传的值来定
      if (_use.name == _all.name) {
        if (_use.children && _use.children.length > 0) {
          dealWithRoute(_use.children, _all.children);
        }
        result.push(_all);
      }
    });
  });
  addDynamicRoute(result);
};

// 添加动态路由,parent默认为home是首页最外层的路由name名
const addDynamicRoute = (useRoute: Array<any>, code = '', parent = '首页') => {
  for (let i = 0; i < useRoute.length; i++) {
    const route = useRoute[i]
    const view = route.viewPath ? route.viewPath : 'admin/home/Index.vue'
    console.error('view：', view)
    const path = code + (route.code ? route.code : '')
    const routePath: RouteRecordRaw = {
      path: path, children: [],
      name: route.name,
      meta: route.meta || {title: route.name},
      component: () =>
        import(/* webpackChunkName: "approval" */ `@/views/${view}`)
    }
    //console.log('routePath', routePath)
    router.addRoute(routePath);
    if (route.children && route.children.length > 0) {
      // 递归添加动态路由
      addDynamicRoute(route.children, path, route.name);
    }
  }
};

export default dealWithRoute;
