const cn = {
  title: '成都译成科技有限责任公司',
  login: {
    systemName: 'cdkjFw 后台管理中心',
    username: '输入邮箱地址',
    password: '输入密码',
    code: '输入验证码',
    login: '登录',
    register: '注册用户',
    forgot: '忘记密码'
  },
  page: {
    index: '首页',
    timestamp: '时间戳转换',
    password: '生成随机密码',
    json: 'JSON格式化',
    tool: '工具'
  }
}
export default cn