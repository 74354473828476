import {createApp, DirectiveBinding} from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// @ts-ignore
import Admin from './Admin.vue'
import router from './router'
import {store} from './store/index'
import './router/route-guard'

import ElementUI from 'element-plus'
import i18n from '@/i18n/i18n'
import 'element-plus/dist/index.css'

let app = createApp(Admin)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

/* 定义全局变量 */
app.config.globalProperties.$isCollapse = false
app.config.globalProperties.$overall = {
  isCollapse: false,
  wsMessage: [] as any
}
app.directive('preventReClick', {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
      el.addEventListener('click', () => {
        if (el.style.pointerEvents != 'none') {
          el.style.pointerEvents = 'none'
          const timeout = binding.value || 1000
          setTimeout(() => {
            el.style.pointerEvents = 'all'
          }, timeout)
        }
      })
    }
  }
)
app.config.globalProperties.$t = (key: any, value: any) => i18n.global.t(key, value)
const setupApp = () => {
  app.use(store)
  app.use(router)
  app.use(ElementUI)
  app.use(CKEditor)
  app.mount('#app')
}
setupApp()