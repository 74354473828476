import { ROUTER_RESOURCES } from '@/utils/enums/cache-enum';
import { Storage } from '@/utils/storage'
import { defineStore } from 'pinia';
import dealWithRoute from '@/router/generate-route';

export const useStore = defineStore('main', {
  state: () => {
    return {
      /** 菜单数据 **/
      menuList: [] as any[]
    }
  },
  /** 获取菜单，添加动态路由 **/
  actions: {
    async addDynamicRoute() {
      /* eslint-disable */
      let nowMenuList: Array<Object> = [];
      if (this.menuList?.length > 0) {
        nowMenuList = this.menuList;
      } else {
        const menus = Storage.get(ROUTER_RESOURCES);
        if (!menus) {
          return false
        }
        // 通过接口拿菜单数据
        nowMenuList = typeof menus === "object" ? menus : JSON.parse(menus) as any[];
        this.menuList = nowMenuList as any;
      }
      /** 将拿到的菜单去匹配生成动态路由 **/
      dealWithRoute(nowMenuList);
      return true
    }
  }
}
)
